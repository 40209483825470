.footer-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.title {
    font-family: 'Inria Serif', serif;
    font-weight: 700;
    font-size: 40px;
}

.copyright {
    font-weight: 700;
    font-size: 20px;
    font-family: 'Lato';
}

/* screen size adjustments */
@media screen and (max-width: 480px) {
    .title {
        font-size: 18px;
    }

    .copyright {
        font-size: 12px;
    }
}

@media screen and (min-width: 481px)  and (max-width: 768px) {
    .footer-wrapper {
        font-size: 10px;
    }
}

@media screen and (min-width: 769px) and (max-width: 950px) {
    .footer-wrapper {
        font-size: 12px;
    }
}

@media screen and (min-width: 951px) and (max-width: 1200px) {
    .footer-wrapper {
        font-size: 14px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .footer-wrapper {
        font-size: 18px;
    }
}

@media screen and (min-width: 1501px) {
    .footer-wrapper {
        font-size: 20px;
    }
}
