.welcome-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
  }

.welcome-img {
    width: 97%;
    right: 0;
    height: auto;
    margin-left: auto;
    z-index: 0;
}

.welcome-cover {
    background: rgba(24, 7, 2, 0.77);
    position: absolute;
    top: 0;
    right: 0;
    width: 97%;
    height: 100%;
    z-index: 1;
}

.welcome-text {
    position: absolute;
    color: #FFF;
    font-family: 'Inria Serif', serif;
    font-style: italic;
    font-weight: 700px;
    line-height: initial;
    left: 15%;
    top: 30%;
    text-align: left;
    z-index: 2;
}

.about1 {
    position: absolute;
    width: 97%;
    max-width: 97%;
    opacity: 0.6;
    background: #C49F74;
    top: 77%;
    z-index: 3;
}

.statement-text {
    position: absolute;
    color: #FFF;
    font-style: normal;
    font-family: 'Lato', sans-serif;
    font-weight: 50px;
    left: 10%;
    line-height: 1.5;
    text-align: left;
    z-index: 5;
}

.about2 {
    position: absolute;
    left: 3%;
    width: 94%;
    height: calc(100% * .23);
    opacity: 0.6;
    background: #3F1C13;
    bottom: 0%;
    z-index: 4;
    display: flex;
}

.about-text {
    position: absolute;
    color: #171613;
    font-family: 'Lato', sans-serif;
    left: 10%;
    top: 112%;
    font-style: normal;
    font-weight: 50px;
    z-index: 6;
}

.about-bar {
    position: absolute;
    width: 1px;
    background: #000;
    top: 112%;
    left: 20%;
    z-index: 7;
}

.about-message {
    position: absolute;
    text-align: left;
    color: #171613;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    top: 112%;
    left: 24%;
    height: 10vw;
    z-index: 8;
}

.trigger1 {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.trigger3 {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.trigger4 {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
}

.trigger5 {
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

.animate {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeUp {
    -webkit-animation-name: fadeUp;
    animation-name: fadeUp;
}

@-webkit-keyframes fadeLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeLeft {
    -webkit-animation-name: fadeLeft;
    animation-name: fadeLeft;
}

@-webkit-keyframes fadeLeftSpecial {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 0.6;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeLeftSpecial {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 0.6;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeLeftSpecial {
    -webkit-animation-name: fadeLeftSpecial;
    animation-name: fadeLeftSpecial;
}

/* screen size adjustments */
@media screen and (max-width: 480px) {
    .welcome-wrapper {
        margin-bottom: 110px;
    }
    .welcome-text {
        font-size: 18px;
    }
    .statement-text {
        font-size: 0px;
        right: 40%;
        top: 82%;
    }
    .about-text {
        font-size: 8px;
    }
    .about-message {
        font-size: 12px;
        right: 6%;
    }
    .about1 {
        height: 100%; 
    }
    .about-bar {
        height: 24vw;
    }
}

@media screen and (min-width: 481px)  and (max-width: 768px) {
    .welcome-wrapper {
        margin-bottom: 140px;
    }
    .welcome-text {
        font-size: 24px;
    }
    .statement-text {
        font-size: 10px;
        right: 40%;
        top: 84%;
    }
    .about-text {
        font-size: 8px;
    }
    .about-message {
        font-size: 14px;
        right: 6%;
    }
    .about1 {
        height: calc(100% * 0.7); 
    }
    .about-bar {
        height: 11vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 950px) {
    .welcome-wrapper {
        margin-bottom: 180px;
    }
    .welcome-text {
        font-size: 28px;
    }
    .statement-text {
        font-size: 12px;
        right: 40%;
        top: 84%;
    }
    .about-text {
        font-size: 10px;
    }
    .about-message {
        font-size: 18px;
        right: 7%;
    }
    .about1 {
        height: calc(100% * 0.7); 
    }
    .about-bar {
        height: 11vw;
    }
}

@media screen and (min-width: 951px) and (max-width: 1200px) {
    .welcome-wrapper {
        margin-bottom: 240px;
    }
    .welcome-text {
        font-size: 40px;
    }
    .statement-text {
        font-size: 14px;
        right: 45%;
        top: 84%;
    }
    .about-text {
        font-size: 12px;
    }
    .about-message {
        font-size: 22px;
        right: 8%;
    }
    .about1 {
        height: calc(100% * 0.7); 
    }
    .about-bar {
        height: 11vw;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .welcome-wrapper {
        margin-bottom: 300px;
    }
    .welcome-text {
        font-size: 48px;
    }
    .statement-text {
        font-size: 18px;
        right: 45%;
        top: 84%;
    }
    .about-text {
        font-size: 15px;
    }
    .about-message {
        font-size: 24px;
        right: 10%;
    }
    .about1 {
        height: calc(100% * 0.7); 
    }
    .about-bar {
        height: 11vw;
    }
}

@media screen and (min-width: 1501px) and (max-width: 1800px) {
    .welcome-wrapper {
        margin-bottom: 360px;
    }
    .welcome-text {
        font-size: 64px;
    }
    .statement-text {
        font-size: 20px;
        right: 45%;
        top: 84%;
    }
    .about-text {
        font-size: 20px;
    }
    .about-message {
        font-size: 32px;
        right: 12%;
    }
    .about1 {
        height: calc(100% * 0.7); 
    }
    .about-bar {
        height: 11vw;
    }
}

@media screen and (min-width: 1801px) {
    .welcome-wrapper {
        margin-bottom: 450px;
    }
    .welcome-text {
        font-size: 64px;
    }
    .statement-text {
        font-size: 22px;
        right: 45%;
        top: 84%;
    }
    .about-text {
        font-size: 20px;
    }
    .about-message {
        font-size: 32px;
        right: 14%;
    }
    .about1 {
        height: calc(100% * 0.7); 
    }
    .about-bar {
        height: 11vw;
    }
}
