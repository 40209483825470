.wrapper {
    display: flex;
    height: fit-content;
    justify-content: space-around;
    padding: 50px;
    margin-bottom: 80px;
}

.text {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.meeting-text {
    font-family: 'Lato';
    font-weight: 300;
}

.header-text {
    font-family: 'Inria Serif';
    font-weight: 700;
    font-style: normal;
}


.btn, .btn:hover{
    border-radius: 0;
    background: #583928;
    color: white;
    text-align: center;
    font-family: 'Lato';
}

.media { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.trigger {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.animate {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

/* screen size adjustments */
@media screen and (max-width: 480px) {
    .wrapper {
        margin-bottom: 0px;
    }
    .header-text {
        font-size: 16px;
        margin-bottom: 2px;
    }
    .meeting-text {
        font-size: 12px;
    }
    .img {
        height: 70px;
        margin-bottom: 10px;
    }
    .btn , .btn:hover {
        width: 90px;
        font-size: 8px;
    }
}

@media screen and (min-width: 481px)  and (max-width: 768px) {
    .wrapper {
        margin-bottom: 12px;
    }
    .header-text {
        font-size: 16px;
        margin-bottom: 2px;
    }
    .meeting-text {
        font-size: 12px;
    }
    .img {
        height: 70px;
        margin-bottom: 10px;
    }
    .btn , .btn:hover {
        width: 90px;
        font-size: 8px;
    }
}

@media screen and (min-width: 769px) and (max-width: 950px) {
    .wrapper {
        margin-bottom: 24px;
    }
    .header-text {
        font-size: 24px;
        margin-bottom: 2px;
    }
    .meeting-text {
        font-size: 18px;
    }
    .img {
        height: 100px;
        margin-bottom: 20px;
    }
    .btn , .btn:hover {
        width: 120px;
        font-size: 12px;
    }
}

@media screen and (min-width: 951px) and (max-width: 1200px) {
    .wrapper {
        margin-bottom: 36px;
    }
    .header-text {
        font-size: 32px;
        margin-bottom: 12px;
    }
    .meeting-text {
        font-size: 18px;
    }
    .img {
        height: 120px;
        margin-bottom: 20px;
    }
    .btn , .btn:hover {
        width: 120px;
        font-size: 12px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .wrapper {
        margin-bottom: 60px;
    }
    .header-text {
        font-size: 42px;
        margin-bottom: 18px;
    }
    .meeting-text {
        font-size: 24px;
    }
    .img {
        height: 150px;
        margin-bottom: 30px;
    }
    .btn , .btn:hover {
        width: 150px;
        font-size: 16px;
    }
}

@media screen and (min-width: 1501px) {
    .wrapper {
        margin-bottom: 80px;
    }
    .header-text {
        font-size: 56px;
        margin-bottom: 20px;
    }
    .meeting-text {
        font-size: 32px;
    }
    .img {
        height: 190px;
        margin-bottom: 40px;
    }
    .btn, .btn:hover {
        width: 180px;
        font-size: 20px;
    }
}