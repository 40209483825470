.nav-wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
}

.title-text {
    font-family: 'Inria Serif', serif;
    font-style: normal;
    font-weight: 550;
    line-height: 1;
}

.nav-options {
    display: flex;
    align-items: center;
}

.nav-button {
    font-family: 'Lato', sans-serif;
    font-weight: 50px;
    text-align: center;
    border: none;
    background-color: transparent;
    margin-right: 80px;
}

.trigger1 {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.trigger2 {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.trigger3 {
    -webkit-animation-delay: 0.9s;
    -moz-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

.trigger4 {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
}

.animate {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes fadeUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeUp {
    -webkit-animation-name: fadeUp;
    animation-name: fadeUp;
}

/* screen size adjustments */
@media screen and (max-width: 480px) {
    .title-text {
        position: absolute;
        font-size: 18px;
        left: 5%;
    }
    .nav-wrapper {
        height: 55px;
    }
    .nav-options {
        display: none;
    }
}

@media screen and (min-width: 481px)  and (max-width: 768px) {
    .nav-wrapper {
        justify-content: space-around;
        align-items: center;
    }    
    .title-text {
        font-size: 24px;
    }
    .nav-wrapper {
        height: 65px;
    }
    .nav-button {
        font-size: 0px;
    }
}

@media screen and (min-width: 769px) and (max-width: 950px) {
    .nav-wrapper {
        justify-content: space-around;
        align-items: center;
    }    
    .title-text {
        font-size: 24px;
    }
    .nav-wrapper {
        height: 65px;
    }
    .nav-button {
        font-size: 0px;
    }
}

@media screen and (min-width: 951px) and (max-width: 1200px) {
    .nav-wrapper {
        justify-content: space-around;
        align-items: center;
    }    
    .title-text {
        font-size: 29px;
    }
    .nav-wrapper {
        height: 110px;
    }
    .nav-button {
        font-size: 20px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .nav-wrapper {
        justify-content: space-around;
        align-items: center;
    }    
    .title-text {
        font-size: 34px;
    }
    .nav-wrapper {
        height: 110px;
    }
    .nav-button {
        font-size: 24px;
    }
}

@media screen and (min-width: 1501px) {
    .nav-wrapper {
        justify-content: space-around;
        align-items: center;
    }    
    .title-text {
        font-size: 40px;
    }
    .nav-wrapper {
        height: 110px;
    }
    .nav-button {
        font-size: 28px;
    }
}
