.winner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 200px;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    background: #593A29;
}

.single-pic {
    width: 80%;
}

.winner-wrapper {
    margin-bottom: 20px;
}

.top-wrapper {
    display: flex;
    height: 100%;
    padding-right: 68px;
    padding-left: 68px;
    padding-top: 2vw;
    margin-bottom: 4vw;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .top-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }
}

.winner-box {
    padding-top: 30px;
    background: #593A29;
}

.carousel {
    width: 80%;
    height: 80%;
}

.community-message {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-weight: 50px;
    line-height: 1.5;
    text-align: left;
}

.winners-text1, .winners-text2 {
    font-family: 'Inria Serif', serif;
    font-weight: 700;
}

.winners-text1 {
    color: #DCDCDC;
}

.winners-text2 {
    color: #282828;
}

.winner-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 550px;
}

.carousel-inner {
    padding: 100px;
    position: relative;
    /* width: 92vw; */
    top: 20%;
    height: auto;
}

.trigger {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.animate {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes fadeLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeLeft {
    -webkit-animation-name: fadeLeft;
    animation-name: fadeLeft;
}

/* screen size adjustments */
@media screen and (max-width: 480px) {

    .winner-box {
        width: 980px;
        height: 400px;
    }
  
    .community-message {
        font-size: 8px;
    }

    .winners-text1, .winners-text2 {
        font-size: 24px;
    }

    .winner-text-wrapper {
        min-width: auto;
        flex-direction: column;
    }

}

@media screen and (min-width: 481px)  and (max-width: 768px) {
  
    .winner-box {
        width: 980px;
        height: 630px;
    }
    
    .community-message {
        font-size: 10px;
    }

    .winner-text-wrapper {
        flex-direction: row;
        gap: 8px;
    }

    .winners-text1, .winners-text2 {
        font-size: 24px;
    }
}

@media screen and (min-width: 769px) and (max-width: 950px) {
  
    .winner-box {
        width: 1100px;
        height: 700px;
    }
    
    .community-message {
        left: 5%;
        right: 10%;
        top: 16%;
        font-size: 12px;
    }

    .winner-text-wrapper {
        flex-direction: row;
        gap: 8px;
    }

    .winners-text1, .winners-text2 {
        font-size: 28px;
    }
}

@media screen and (min-width: 951px) and (max-width: 1200px) { /*switch align starting here*/
    .winner-box {
        width: 1580px;
        height: 800px;
    }
    
    .community-message {
        font-size: 14px;
    }

    .winner-text-wrapper {
        flex-direction: row;
        gap: 8px;
    }

    .winners-text1, .winners-text2 {
        font-size: 40px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .winner-box {
        width: 1580px;
        height: 800px;
    }
   
    .community-message {
        font-size: 18px;
    }

    .winners-text1, .winners-text2 {
        font-size: 48px;
    }
}

@media screen and (min-width: 1501px) {
    .winner-box {
        width: 1580px;
        height: 1020px;
    }

    .community-message {
        font-size: 20px;
        margin-left: 4vw;
    }

    .winners-text1, .winners-text2 {
        font-size: 64px;
        left: 10%;
    }

    /* .single-pic {
        width: 100%;
        max-width: 100%;
        height: auto;
    } */
}
